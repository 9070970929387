<template>
  <div>
    <!-- 顶部搜索框 -->
    <div class="usertop">
      <!-- 左侧搜索 -->
      <div>
        <el-form
          :inline="true"
          :model="formInline"
          class="demo-form-inline w-144"
        >
          <el-form-item>
            <el-date-picker
              v-model="formInline.time"
              type="date"
              @change="timeline"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="院区:">
            <el-select v-model="formInline.campus" @change="topInline">
              <el-option
                v-for="item in Oncampus"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="时段:">
            <el-select v-model="formInline.region" @change="topInline">
              <el-option
                v-for="item in shiduan"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              />
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <!-- 右侧按钮 -->
      <div style="margin-top: 8px">
        <el-button class="color-yellow" @click="modParient(scope.row)">
          <i class="fa fa-print"></i>
          打印全部
        </el-button>
        <el-button class="color-yellow" @click="modParient(scope.row)">
          <i class="fa fa-print"></i>
          打印小票
        </el-button>
        <el-button class="color-blue" @click="tongshow">
          <i class="fa fa-bar-chart"></i>
          统计
        </el-button>
        <el-button class="color-vice" @click="derive('drug')">
          <i class="fa fa-file-text-o"></i>
          导出
        </el-button>
      </div>
    </div>

    <!-- 表格内容 -->
    <div>
      <el-table
        v-loading="loading"
        ref="multipleTableRef"
        :data="tableData"
        style="width: 100%"
        height="500"
        border
      >
        <el-table-column property="bed_num" label="床位号" />
        <el-table-column property="name" label="小组" />
        <el-table-column property="case_no" label="病案号" />
        <el-table-column property="patient_name" label="患者姓名" />
        <el-table-column property="age" label="年龄" />
        <el-table-column property="dict_sex_name" label="性别" />
        <el-table-column property="str_key" label="药品名称" />
        <el-table-column property="str_value" label="药品规格" />
        <!-- <el-table-column width="180" property="request_data" label="数量" /> -->
      </el-table>

      <div class="pagination-box">
        <el-pagination
          v-model:currentPage="pageData.currentPage"
          v-model:page-size="pageData.pageSize"
          :page-sizes="[10, 20, 50, 100]"
          :background="true"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageData.totalSum"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>

    <!-- 统计弹窗 -->
    <div>
      <BaseDialog
        title="药品——统计表"
        :isshow="show"
        @handleShow="
          (val) => {
            show = val
          }
        "
        width="50%"
        height="auto"
      >
        <!-- 顶部 -->
        <div class="displya_flex">
          <!-- 左侧 -->
          <div></div>
          <!-- 右侧 -->
          <div>
            <div style="margin: 10px 0">
              <el-button @click="search" class="color-main" type="primary">
                <i class="fa fa-print"></i>打印
              </el-button>
              <el-button
                @click="derive('drug_export')"
                class="color-main"
                type="primary"
              >
                <i class="fa fa-file-text-o"></i>导出
              </el-button>
            </div>
          </div>
        </div>
        <!-- 表格内容 -->
        <div>
          <el-table class="sss" :data="tablelist" stripe style="width: 100%" height="400">
            <el-table-column prop="indes" label="序号" />
            <el-table-column prop="drug" label="药品" />
            <el-table-column prop="dosage" label="规格" />
            <el-table-column prop="count" label="数量" />
          </el-table>
        </div>
      </BaseDialog>
    </div>
  </div>
</template>

<script>
import { toRefs, reactive, onMounted } from 'vue'
import service from '@/utils/request'
import { TimeYear, downLoadXlsx } from '@/utils/tool.js'
import BaseDialog from '@/components/Dialog/index.vue'
export default {
  components: {
    BaseDialog,
  },
  setup() {
    const state = reactive({
      formInline: {
        region: '上午',
        time: TimeYear(new Date()),
      },
      loading: true,
      Oncampus: [],
      tablelist: [],
      pageData: {
        currentPage: 1,
        pageSize: 10,
        totalSum: 0,
      },
      show: false,
      tableData: [],
      shiduan: [
        { name: '上午', id: 1 },
        { name: '下午', id: 2 },
        { name: '晚上', id: 3 },
        { name: '全天', id: 4 },
      ],
    })
    onMounted(() => {
      tobody()
      hospital()
    })
    // 获取列表
    const tobody = async () => {
      state.loading = true
      let data = {
        type: 1,
        time: state.formInline.time,
        dict_code: state.formInline.region,
        hospital: state.formInline.campus,
        size: state.pageData.pageSize,
        current: state.pageData.currentPage,
      }
      let res = await service.post('/api/dialysis_before/dialysisRecord', data)
      if (res.code === 0) {
        if (res.data.count > 0) {
          state.tableData = res.data.record
          state.pageData.totalSum = res.data.count
        } else {
          state.tableData = []
        }
      }
      state.loading = false
    }
    // 条数改变时
    const handleSizeChange = (e) => {
      state.pageData.pageSize = e
      tobody()
    }
    // 页数改变时
    const handleCurrentChange = (e) => {
      state.pageData.currentPage = e
      tobody()
    }
    // 查询
    const topInline = () => {
      tobody()
    }
    // 日期查询
    const timeline = (e) => {
      state.formInline.time = TimeYear(e)
      tobody()
    }
    // 获取院区配置
    const hospital = async () => {
      let res = await service.post('/api/hospital/list', { parent_id: 0 })
      if (res.code === 0) {
        state.Oncampus = res.data
        state.formInline.campus = res.data[0].id
        tobody()
      }
    }
    // 导出
    const derive = (row) => {
      let data = {
        type: row,
        date: state.formInline.time,
        dict_code: state.formInline.region,
        hospital_area_id: state.formInline.campus,
        current: state.pageData.currentPage,
        size: state.pageData.pageSize,
      }
      downLoadXlsx('/api/dialysis_before/list', data, '透析药品表.xlsx')
    }
    // 统计表
    const tongshow = async () => {
      state.show = true
      let data = {
        type: 'drug_statistic',
        date: state.formInline.time,
        dict_code: state.formInline.region,
        hospital_area_id: state.formInline.campus,
        current: state.pageData.currentPage,
        size: state.pageData.pageSize,
      }
      let res = await service.post('/api/dialysis_before/list', data)
      if (res.code === 0) {
        state.tablelist = res.data
        state.tablelist.map((v, index) => {
          v.indes = index
        })
      }
    }
    return {
      ...toRefs(state),
      tobody,
      handleSizeChange,
      handleCurrentChange,
      topInline,
      derive,
      hospital,
      timeline,
      tongshow,
    }
  },
}
</script>

<style scoped lang="scss">
.displya_flex {
  display: flex;
  justify-content: space-between;
}
.usertop {
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  &:deep(.el-alert--warning.is-light) {
    background-color: rgba(49, 102, 174, 0.1);
    color: #3166ae;
  }
}
.demo-form-inline {
  margin-top: 8px;
  height: 38px;
}
</style>
